import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';

import Logo from '../Logo';


function Header({ siteSetting }) {
  const router = useRouter()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleLink = (e, path) => {
    document.body.classList.remove('locked');
    document.body.offsetHeight;

    switch (path) {
      case 'about':
        router.push('/#about', null, { scroll: false });
        break;
      case 'team':
        router.push('/#team', null, { scroll: false });
        break;
      case 'bonuses':
        router.push('/#bonuses', null, { scroll: false });
        break;
      case 'reviews':
        router.push('/#reviews', null, { scroll: false });
        break;
      case 'contacts':
        router.push('/#contacts', null, { scroll: false });
        break;
      case 'form':
        document.getElementById('request').scrollIntoView()
        break;
      default:
        return;
    }

    setMobileMenuOpen(false);
  }

  useEffect(() => {
    if (mobileMenuOpen === true) {
      document.body.classList.add('locked');
    } else if (mobileMenuOpen === false) {
      document.body.classList.remove('locked');
    }
  }, [mobileMenuOpen])

  return (
    <header className="relative z-[100]">
      <div className="container-main w-full h-[var(--header-height)]">
        <div className="centered-y justify-between w-full h-full relative z-10">
          <Link href="/">
            <Logo
              className={`sm:w-vw-60 w-vw-104`}
              textColor={mobileMenuOpen ? '#ffffff' : '#3B3B3B'}
            />
          </Link>

          <div className="desktop-only centered-y justify-end h-full">
            <button onClick={(e) => handleLink(e, "about")} className="hover:text-black/50 transition-colors">
              <p className="text-vw-lg">О нас</p>
            </button>

            <button onClick={(e) => handleLink(e, "team")} className="hover:text-black/50 transition-colors ml-vw-11">
              <p className="text-vw-lg">Команда</p>
            </button>

            <button onClick={(e) => handleLink(e, "bonuses")} className="hover:text-black/50 transition-colors ml-vw-11">
              <p className="text-vw-lg">Бонусы</p>
            </button>

            <button onClick={(e) => handleLink(e, "reviews")} className="hover:text-black/50 transition-colors ml-vw-11">
              <p className="text-vw-lg">Отзывы</p>
            </button>

            <button onClick={(e) => handleLink(e, "contacts")} className="hover:text-black/50 transition-colors ml-vw-11">
              <p className="text-vw-lg">Контакты</p>
            </button>
          </div>

          <div className="mobile-only px-main">
            <button type="button" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
              {mobileMenuOpen ? (
                <svg className="w-vw-16" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="4.27148" y="28.3125" width="34" height="2" transform="rotate(-45 4.27148 28.3125)" fill="#fff"/>
                  <rect x="5.68555" y="4.27344" width="34" height="2" transform="rotate(45 5.68555 4.27344)" fill="#fff"/>
                </svg>
              ) : (
                <svg className="w-vw-16" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect y="5" width="34" height="2" fill="#3B3B3B" />
                  <rect y="16" width="34" height="2" fill="#3B3B3B" />
                  <rect y="27" width="34" height="2" fill="#3B3B3B" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <div
        className="bg-secondary fixed inset-0 z-0 transition-all"
        style={{
          visibility: mobileMenuOpen ? 'visible' : 'hidden',
          opacity: mobileMenuOpen ? 1 : 0
        }}
      >
        <div className="flex flex-col items-start w-full pt-[var(--header-height)] pl-vw-42">
          <button onClick={e => handleLink(e, "about")} className="text-white hover:text-primary transition-colors mt-vw-28">
            <p className="text-vw-4xl">О нас</p>
          </button>

          <button onClick={e => handleLink(e, "team")} className="text-white hover:text-primary transition-colors mt-vw-18">
            <p className="text-vw-4xl">Команда</p>
          </button>

          <button onClick={e => handleLink(e, "bonuses")} className="text-white hover:text-primary transition-colors mt-vw-18">
            <p className="text-vw-4xl">Бонусы</p>
          </button>

          <button onClick={e => handleLink(e, "reviews")} className="text-white hover:text-primary transition-colors mt-vw-18">
            <p className="text-vw-4xl">Отзывы</p>
          </button>

          <button onClick={e => handleLink(e, "contacts")} className="text-white hover:text-primary transition-colors mt-vw-18">
            <p className="text-vw-4xl">Контакты</p>
          </button>
        </div>

        <div className="px-main mt-vw-36">
          <button
            type="button"
            onClick={(e) => handleLink(e, "form")}
            className="group button-primary relative"
          >
            Подобрать квартиру

            <span
              className={`
              sm:text-vw-sm text-vw-3xl text-black
              bg-white group-hover:bg-primary sm:rounded-vw-2xl rounded-vw-4xl
              absolute right-0 top-0 -translate-y-1/2
              transition-colors
              sm:px-vw-3 px-vw-7 sm:pt-vw-1 pt-vw-2.5 sm:pb-vw-1.5 pb-vw-3.5
            `}
            >
              Бесплатно
            </span>
          </button>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {};

Header.defaultProps = {};


export default Header;
