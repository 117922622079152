import Link from 'next/link';

import PropTypes from 'prop-types';

import LogoSvg from '../../public/svg/logo.svg'
import ArrowAngleSvg from '../../public/svg/arrow-45.svg'
import EvgmedSvg from '../../public/svg/evgmed.svg'


function Footer({ siteSetting }) {
  return (
    <footer className="container-main sm:h-[var(--footer-height)] bg-gray-light sm:rounded-t-vw-5xl rounded-t-vw-7xl relative z-50 !px-0">
      <div className="container-main centered-y sm:flex-row flex-col h-full justify-between sm:py-0 py-vw-20">
        <button type="button" onClick={() => window.scrollTo({ x: 0, y: 0 })}>
          <LogoSvg className="sm:w-vw-18 w-vw-36 [&>*]:fill-primary" />
        </button>

        <div className="centered sm:flex-row flex-col w-full overflow-hidden sm:pt-0 pt-vw-12 sm:px-0 px-vw-44">
          <Link
            href={siteSetting.privacyPolicyPage.href}
            className="sm:text-vw-lg text-center text-vw-4.5xl leading-tight hover:text-primary transition-colors"
          >
            Политика конфиденциальности
          </Link>

          <a
            href={siteSetting.telegram.url}
            target="_blank"
            rel="noreferrer noopener"
            className="sm:text-vw-lg text-center text-vw-4.5xl leading-tight hover:text-primary transition-colors sm:mx-vw-20 sm:my-0 my-vw-10"
          >
            Telegram <ArrowAngleSvg className="inline sm:w-vw-3 w-vw-6" />
          </a>

          <a
            href={siteSetting.instagram.url}
            target="_blank"
            rel="noreferrer noopener"
            className="sm:text-vw-lg text-center text-vw-4.5xl leading-tight hover:text-primary transition-colors"
          >
            Instagram <ArrowAngleSvg className="inline sm:w-vw-3 w-vw-6" />
          </a>
        </div>

        <a
          href="https://evgmed.com"
          target="_blank"
          rel="noreferrer noopener"
          className="centered sm:w-vw-18 w-vw-36 sm:h-vw-18 opacity-40 hover:opacity-100 transition-opacity sm:mt-0 mt-vw-20"
        >
          <EvgmedSvg className="w-full" />
        </a>
      </div>
    </footer>
  )
}

Footer.propTypes = {};

Footer.defaultProps = {};


export default Footer;
