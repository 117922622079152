import { useRef, useState, useEffect } from 'react';

import AudioMutedSvg from '../../public/svg/audio-muted.svg'
import AudioUnmutedSvg from '../../public/svg/audio-unmuted.svg'
import PlaySvg from '../../public/svg/play.svg'
import CrossSvg from '../../public/svg/cross.svg'


function PinnedVideo({ data }) {
  const videoRef = useRef(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (videoRef.current) {
      if (isOpen === true) {
        videoRef.current.play()
      } else if (isOpen === false) {
        videoRef.current.pause()
        videoRef.current.currentTime = 0
      }
    }
  }, [isOpen, videoRef])

  return (
    <div className="fixed sm:left-vw-14 left-vw-8 bottom-vw-8 z-[70]">
      <div className="flex flex-col items-start">
        <div
          className="aspect-[9/14] sm:w-vw-52 w-vw-120 sm:rounded-vw-5xl rounded-vw-7xl overflow-hidden mb-vw-2"
          style={{ display: isOpen ? '' : 'none' }}
        >
          <video ref={videoRef} loop playsInline className="w-full h-full object-cover">
            <source src={data.src} type="video/mp4" />
          </video>
        </div>

        <button className="centered-y text-left bg-white rounded-full sm:pr-vw-4 pr-vw-8" onClick={() => setOpen(!isOpen)}>
          <div className="centered sm:w-vw-10 w-vw-24 sm:h-vw-10 h-vw-24 bg-primary rounded-full relative">
            {isOpen ? (
              <CrossSvg className="sm:w-vw-5 w-vw-10" />
            ) : (
              <PlaySvg className="sm:w-vw-5 w-vw-10 sm:ml-vw-1 ml-vw-2" />
            )}
          </div>

          <p className="sm:text-vw-sm text-vw-3xl leading-none sm:ml-vw-2 ml-vw-4 sm:mb-vw-0.5 mb-vw-1.5">
            {isOpen ? <>Закрыть</> : <>Давайте<br />знакомиться</>}
          </p>
        </button>
      </div>
    </div>
  )
}

PinnedVideo.defaultProps = {};

PinnedVideo.propTypes = {};


export default PinnedVideo;
