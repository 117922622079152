import { useEffect } from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PinnedVideo from '../../components/PinnedVideo/PinnedVideo';

import * as Chatra from '@chatra/chatra';


const WagtailUserbar = dynamic(() => import('../../components/WagtailUserbar'));


const BasePage = ({ children, seo, siteSetting, wagtailUserbar }) => {
	const {
		seoHtmlTitle,
		seoMetaDescription,
		seoOgTitle,
		seoOgDescription,
		seoOgUrl,
		seoOgImage,
		seoOgType,
		seoTwitterTitle,
		seoTwitterDescription,
		seoTwitterUrl,
		seoTwitterImage,
		seoMetaRobots,
		canonicalLink,
	} = seo;

	useEffect(() => {
		// if (process.env.NODE_ENV === 'production') {
			const chatraConfig = {
				ID: 'Gczkp6tw6zWow6P8i',
				setup: {
					customWidgetButton: '#chatra-chat-button'
				},
			}
			Chatra('init', chatraConfig)
			Chatra('pageView')
		// }
	}, [])

	return (
		<>
			<Head>
				<title>{seoHtmlTitle}</title>
				<meta name="robots" content={seoMetaRobots} />
			
				{!!seoMetaDescription && (
					<meta name="description" content={seoMetaDescription} />
				)}
				{!!seoOgTitle && (
					<meta property="og:title" content={seoOgTitle} />
				)}
				{!!seoOgDescription && (
					<meta property="og:description" content={seoOgDescription} />
				)}
				{!!seoOgUrl && (
					<meta property="og:url" content={seoOgUrl} />
				)}
				{!!seoOgImage && (
					<meta property="og:image" content={seoOgImage} />
				)}
				{!!seoOgType && (
					<meta property="og:type" content={seoOgType} />
				)}
				{!!seoTwitterTitle && (
					<meta property="twitter:title" content={seoTwitterTitle} />
				)}
				{!!seoTwitterDescription && (
					<meta property="twitter:description" content={seoTwitterDescription} />
				)}
				{!!seoTwitterUrl && (
					<meta property="twitter:url" content={seoTwitterUrl} />
				)}
				{!!seoTwitterImage && (
					<meta property="twitter:image" content={seoTwitterImage} />
				)}
				{!!canonicalLink && (
					<link rel="canonical" href={canonicalLink} />
				)}
			</Head>
			
			<main>
				<Header siteSetting={siteSetting} />

				{children}

				<Footer siteSetting={siteSetting} />

				{siteSetting.pinnedVideo && (
					<PinnedVideo data={siteSetting.pinnedVideo} />
				)}

				<button
					id="chatra-chat-button"
					className="centered sm:w-vw-10 sm:h-vw-10 w-vw-24 h-vw-24 bg-primary rounded-full fixed bottom-vw-8 sm:right-vw-14 right-vw-8 z-[70]"
				>
					<svg className="sm:w-vw-5 w-vw-12" viewBox="0 0 481 401" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill="#3C3C3A" d="M240.409 0.507812C372.972 0.507812 480.409 87.6485 480.409 195.194C480.409 302.739 372.972 389.88 240.409 389.88C204.409 389.88 170.378 383.234 139.722 371.722C87.5186 400.512 42.8311 400.512 14.4906 400.512C11.4906 400.512 42.8311 361.871 53.8467 317.528C20.4717 284.113 0.40918 241.619 0.40918 195.194C0.40918 87.6485 107.847 0.507812 240.409 0.507812Z" />
					</svg>
				</button>
			</main>

			{!!wagtailUserbar && <WagtailUserbar {...wagtailUserbar} />}
		</>
	);
}
		

BasePage.defaultProps = {
	seo: {},
	siteSetting: {}
};

BasePage.propTypes = {
	children: PropTypes.node,
	seo: PropTypes.shape({
		seoHtmlTitle: PropTypes.string,
		seoMetaDescription: PropTypes.string,
		seoOgTitle: PropTypes.string,
		seoOgDescription: PropTypes.string,
		seoOgUrl: PropTypes.string,
		seoTwitterTitle: PropTypes.string,
		seoMetaRobots: PropTypes.string,
	}),
	siteSetting: PropTypes.shape({
		email: PropTypes.string,
		phone: PropTypes.shape({
			international: PropTypes.string,
			rfc3966: PropTypes.string
		}),
		privacyPolicyPage: PropTypes.shape({
			slug: PropTypes.string
		})
	}),
	wagtailUserbar: PropTypes.shape({
		html: PropTypes.string,
	}),
};

export default BasePage;
